.page-container {
  max-width: 1200px;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
  
  &.flex {
    display: flex;
    align-items: center;
  }

}