.sidebar-menu {
  > a {
    display: block;
    margin-bottom: 12px;

    button {
      width: 100%;
      
      span {
        justify-content: flex-start;
      }
    }
  }
}