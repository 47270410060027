.timeline-table {
  width: 100%;

  th, td {
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #f8f8f8;
    border: solid 1px #ddd;
  }

  td {
    border: solid 1px #ddd
  }
}