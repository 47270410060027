.img-grid {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: black;
    opacity: 0.5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  h1 {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    text-shadow: 1px 1px 3px #333;
  }
}
