.pcredits-head-desc-block {
  margin-bottom: 24px;

  strong {
    display: block;
    margin-bottom: 12px;
  }
}



.pcredits-step1 {
  .checkoutButton {
    margin: 72px 24px;
    text-align: center;
  }

  .custom-amount-input {
    margin: 72px 0 24px;
    > div {
      align-items: center;
    }
  }
  
  .pcredits-price-select {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 40px;
    margin-top: 24px;
  
    .euiCard__image {
      height: 200px;
      overflow: hidden;
  
      img {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }
  
    @media screen and (max-width: 560px) {
      grid-template-columns: 1fr;
    }
  }
}