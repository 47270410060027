@media print {
  body * {
    visibility: hidden;
    height: 595;
    width: 842;
  }
  #certificate,
  #certificate * {
    visibility: visible;
  }

  #certificate {
    display: block !important;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

#certificate {
  display: none;
}

.gallery-container {
  border: solid 1px #ddd;
  border-radius: 4px;
  background: white;
  overflow: hidden;

  .carousel .control-arrow.control-next,
  .carousel .control-arrow.control-prev {
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.2);
  }

  .carousel,
  .carousel .slider-image-container {
    max-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      top: 50%;
    }
  }

  img {
    width: 100%;
  }
}
