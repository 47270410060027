@import url("https://fonts.googleapis.com/css2?family=Literata:wght@200;300;400;500;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400&display=swap");

html,
body,
#root,
.app {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-main);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body.noscroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bitter", serif !important;
}

a,
a:hover,
button:hover,
a:active,
a:focus,
button:active,
button:focus {
  text-decoration: none !important;
}

a {
  color: var(--color-orange);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-primary: "#03071E";
  --color-secondary: "#FFEE32";
  --color-text-secondary: "#85BDBF";
  --font-main: "Roboto", "Helvetica Neue", sans-serif;
  --font-secondary: "Bitter", serif;
}

* {
  box-sizing: border-box;
}

.confetti-bg {
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0.5;
}
